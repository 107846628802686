import React, { useEffect, useState, useCallback } from "react";
import { gql, useQuery } from "@apollo/client";
import { debounce } from "lodash";
import { Spinner } from "../../../assets/images/three-dots-loading";
import SingleWorkflowHistory from "./SingleWorkflowHistory";
// GraphQL Queries
const WorkflowRunStats = gql`
  query WorkflowRunStats(
    $workflowId: ID
    $fubPersonId: ID
    $workflowStatus: String
    $startDate: Date
    $endDate: Date
    $offset: Int
    $limit: Int
    $searchTerm: String
  ) {
    workflowRunStats(
      workflow_id: $workflowId
      fub_person_id: $fubPersonId
      workflow_status: $workflowStatus
      startDate: $startDate
      endDate: $endDate
      offset: $offset
      limit: $limit
      search_term: $searchTerm
    ) {
      offset
      totalRows
      stats {
        session_id
        team_id
        workflow_id
        trigger_uuid
        fub_person_id
        workflow_status
        created_at
        last_updated
        workflow_name,
        trigger_type
      }
    }
  }
`;

const WorkflowHistory = () => {
  const limit = 10;
  const [searchQuery, setSearchQuery] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [workflowStatus, setWorkflowStatus] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [isRefetching, setIsRefetching] = useState(false);

  // Fetch workflow stats
  const { data, fetchMore, refetch } = useQuery(WorkflowRunStats);

  const totalCount = data?.workflowRunStats?.totalRows || 0;
  const totalPages = Math.ceil(totalCount / limit);

  const debouncedSearch = useCallback(
    debounce(async (value, start, end, status) => {
      setIsRefetching(true);
      await refetch({
        limit,
        offset: 0,
        searchTerm: value || "",
        startDate: start || "",
        endDate: end || "",
        workflowStatus: status || "",
      });
      setIsRefetching(false);
    }, 500),
    [refetch]
  );

  useEffect(() => {
    debouncedSearch(searchQuery, startDate, endDate, workflowStatus);
  }, [searchQuery, startDate, endDate, workflowStatus, debouncedSearch]);

  const handlePageNumbers = async (number) => {
    setCurrentPage(number);
    setIsRefetching(true);

    try {
      await fetchMore({
        variables: {
          offset: (number - 1) * limit,
        },
        fetchPolicy: "network-only",
        notifyOnNetworkStatusChange: true,
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev;
          return {
            workflowRunStats: {
              ...fetchMoreResult.workflowRunStats,
              stats: [...fetchMoreResult.workflowRunStats.stats],
            },
          };
        },
      });
    } finally {
      setIsRefetching(false);
    }
  };

  const renderPageNumbers = () => {
    const pages = [];
    const maxVisibleButtons = 5;
    const halfVisibleButtons = Math.floor(maxVisibleButtons / 2);

    let startPage = Math.max(currentPage - halfVisibleButtons, 1);
    let endPage = Math.min(currentPage + halfVisibleButtons, totalPages);

    if (totalPages <= maxVisibleButtons) {
      startPage = 1;
      endPage = totalPages;
    } else {
      if (currentPage <= halfVisibleButtons) {
        endPage = maxVisibleButtons;
      } else if (currentPage + halfVisibleButtons >= totalPages) {
        startPage = totalPages - maxVisibleButtons + 1;
      }
    }

    if (startPage > 1) {
      pages.push(1);
      if (startPage > 2) pages.push("...");
    }

    for (let i = startPage; i <= endPage; i++) {
      pages.push(i);
    }

    if (endPage < totalPages) {
      if (endPage < totalPages - 1) pages.push("...");
      pages.push(totalPages);
    }

    return pages;
  };

  const handleSearchChange = (value) => {
    setSearchQuery(value);
  };

  return (
    <div className="mt-5">
      <section className="bg-white p-3 rounded-3">
        <p>Filter</p>

        <div className="p-0 row row-cols-4">
          <div className="mb-3">
            <input
              placeholder="Search"
              className="form-control input-bn"
              type="text"
              value={searchQuery}
              onChange={(e) => handleSearchChange(e.target.value)}
            />
          </div>

          <div className="mb-3">
            <div className="form-floating w-100 form-group">
              <select
                className="form-select input-bn"
                onChange={(e) => setWorkflowStatus(e.target.value)}
              >
                <option value="">Select</option>
                <option value="SUCCESS">Success</option>
                <option value="FAILED">Failed</option>
                <option value="IN_PROGRESS">In Progress</option>
                <option value="FILTERED">Filtered</option>
              </select>
              <label>
                <span className="d-flex align-items-center">
                  <span>Workflow Status </span>
                </span>
              </label>
            </div>
          </div>

          <div className="mb-3">
            <div className="form-floating w-100 form-group">
              <input
                className="form-control input-bn"
                type="date"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
              />
              <label>
                <span className="d-flex align-items-center">
                  <i
                    className="iconify me-1 mr-1 fs-5"
                    data-icon="mdi:calendar-day"
                  ></i>
                  <span>Starting</span>
                </span>
              </label>
            </div>
          </div>

          <div className="mb-3">
            <div className="form-floating w-100 form-group">
              <input
                className="form-control input-bn"
                type="date"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
              />
              <label>
                <span className="d-flex align-items-center">
                  <i
                    className="iconify me-1 mr-1 fs-5"
                    data-icon="mdi:calendar-day"
                  ></i>
                  <span>Ending</span>
                </span>
              </label>
            </div>
          </div>
        </div>
      </section>

      {isRefetching ? (
        <div
          className="w-100 d-flex justify-content-center align-items-center"
          style={{ height: "20vh" }}
        >
          <span>{Spinner}</span>
        </div>
      ) : data?.workflowRunStats?.stats?.length !== 0 ? (
        <div>
          <section className="d-flex flex-column gap-3">
            <div className="mt-5">
              <div className="d-md-flex flex-wrap border d-none fm-list-container-thead">
                <div className="d-flex flex-wrap col-12 col-md-3 align-items-left justify-content-left pb-2 pt-4 py-md-3 px-4 border-0 border-end border">
                  <b>Workflow ID</b>
                </div>
                <div className="d-flex align-items-center justify-content-left px-4 col col-md-2 border-0 border-end border">
                  <b>Workflow Name</b>
                </div>
                <div className="d-flex align-items-center justify-content-left px-4 col col-md-2 border-0 border-end border">
                  <b>Status</b>
                </div>
                <div className="d-flex align-items-center justify-content-left px-4 col col-md-2 border-0 border-end border">
                  <b>Timestamp</b>
                </div>
                <div className="d-flex align-items-center justify-content-left px-4 col col-md-2 border-0 border-end border">
                  <b>Trigger</b>
                </div>
                <div className="d-flex align-items-center justify-content-left px-4 col col-md-1 border-0 border-end border">
                  <b>Action</b>
                </div>
              </div>

              {data?.workflowRunStats?.stats?.map((workflow) => (
                <SingleWorkflowHistory
                  workflow={workflow}
                />
              ))}
            </div>
          </section>

          <div className="d-flex justify-content-center m-4">
            <nav aria-label="Page navigation example">
              <ul className="pagination pagination-mb">
                {/* Previous Button */}
                <li
                  className={`page-item ${currentPage === 1 ? "disabled" : ""}`}
                >
                  <button
                    onClick={() => handlePageNumbers(currentPage - 1)}
                    className="page-link px-3 text-dark"
                  >
                    &laquo;
                  </button>
                </li>

                {/* Page Numbers */}
                {renderPageNumbers().map((page, index) => (
                  <li
                    key={index}
                    className={`page-item ${
                      currentPage === page ? "active" : ""
                    }`}
                  >
                    {page === "..." ? (
                      <span className="page-link px-3">...</span>
                    ) : (
                      <button
                        onClick={() => handlePageNumbers(page)}
                        className="page-link px-3 text-dark"
                      >
                        {page}
                      </button>
                    )}
                  </li>
                ))}

                {/* Next Button */}
                <li
                  className={`page-item ${
                    currentPage === totalPages ? "disabled" : ""
                  }`}
                >
                  <button
                    onClick={() => handlePageNumbers(currentPage + 1)}
                    className="page-link px-3 text-dark"
                  >
                    &raquo;
                  </button>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      ) : (
        <div
          className="w-100 d-flex justify-content-center align-items-center"
          style={{ height: "20vh" }}
        >
          <span>No data available</span>
        </div>
      )}
    </div>
  );
};

export default WorkflowHistory;
