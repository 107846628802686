import { gql } from "@apollo/client";

export const GET_TEAM_SETTINGS = gql`
  query GetTeamSettings {
    GetTeamSettings {
      team_id
      FormBuilder
      AutomationBuilder
      show_whissel_form_templates
      customizable_leader_boards
      allow_to_manage_template
    }
  }
`;
