import React, { useState, useEffect } from "react";
import { DatePicker, TimePicker } from "antd";
import Select from "react-select";
import dayjs from "dayjs";
import { gql } from "@apollo/client";
import { useMutation } from "@apollo/client";
import { useParams } from "react-router-dom";

const UpdateDealSettings = gql`
  mutation UpdateFormSettings(
    $teamId: String!
    $formId: String
    $dealStagePreventionSetting: GraphQLJSON
  ) {
    UpdateFormSettings(
      team_id: $teamId
      form_id: $formId
      deal_stage_prevention_setting: $dealStagePreventionSetting
    ) {
      form_id
      team_id
    }
  }
`;

export const DispositionFormSettings = ({ GetFormData }) => {
  const params = useParams();
  const [condition, setCondition] = useState({
    field_id: "",
    expected_value: "",
  });
  const [saveMessage, setSaveMessage] = useState(""); // State to store the message
  const [UpdateFormSettings, { loading, error }] = useMutation(
    UpdateDealSettings,
    {
      onCompleted: () => setSaveMessage("Save successfully!"), // Show success message
      onError: () => setSaveMessage("Failed to save. Please try again."), // Show error message
    }
  );

  useEffect(() => {
    if (GetFormData?.deal_stage_prevention_setting) {
      const fieldId = Object?.keys(
        GetFormData?.deal_stage_prevention_setting
      )[0];
      const expectedValue =
        GetFormData?.deal_stage_prevention_setting[fieldId];
      setCondition({ field_id: fieldId, expected_value: expectedValue });
    }
  }, [GetFormData]);

  const updateCondition = (updates) => {
    setCondition((prev) => ({ ...prev, ...updates }));
  };

  const handleSave = async () => {
    setSaveMessage(""); // Clear any previous message
    const formattedData = {
      [condition.field_id]: condition.expected_value,
    };
    
    await UpdateFormSettings({
      variables: {
        teamId: String(GetFormData?.team_id),
        formId: params?.id,
        dealStagePreventionSetting: formattedData,
      },
    });
  
    // Clear the message after 5 seconds
    setTimeout(() => {
      setSaveMessage("");
    }, 3000);
  };
  
  const renderFieldInput = () => {
    const field = GetFormData?.form_fields?.find(
      (f) => String(f?.elementid) === String(condition?.field_id)
    );

    const handleMultiSelectChange = (selectedOptions) => {
      const selectedValues = selectedOptions?.map((option) => option?.value);
      updateCondition({ expected_value: selectedValues });
    };

    switch (field?.type) {
      case "DropDown":
      case "RadioButton":
      case "CheckBox":
        return field.settings?.options ? (
          <Select
            isMulti
            options={field?.settings?.options?.map((option) => ({
              value: option?.value,
              label: option?.value,
            }))}
            value={
              condition.expected_value
                ? field.settings.options
                    .filter((option) =>
                      (condition?.expected_value || [])?.includes(option?.value)
                    )
                    ?.map((option) => ({
                      value: option?.value,
                      label: option?.value,
                    }))
                : []
            }
            onChange={handleMultiSelectChange}
            className="basic-multi-select"
            classNamePrefix="select"
          />
        ) : (
          <p className="text-muted">No options available</p>
        );

      case "Ratings":
        return (
          <Select
            isMulti
            options={[1, 2, 3, 4, 5]?.map((num) => ({
              value: num.toString(),
              label: num.toString(),
            }))}
            value={
              condition.expected_value
                ? [1, 2, 3, 4, 5]
                    ?.filter((num) =>
                      (condition.expected_value || [])?.includes(num.toString())
                    )
                    ?.map((num) => ({
                      value: num.toString(),
                      label: num.toString(),
                    }))
                : []
            }
            onChange={handleMultiSelectChange}
            className="react-select-container"
            classNamePrefix="react-select"
          />
        );

      case "Date":
        return (
          <DatePicker
            selected={
              condition.expected_value
                ? dayjs(condition.expected_value, "MM/DD/YYYY")?.toDate()
                : null
            }
            onChange={(date) =>
              updateCondition({
                expected_value: date ? dayjs(date)?.format("MM/DD/YYYY") : "",
              })
            }
            className="form-control"
          />
        );

      case "Time":
        return (
          <TimePicker
            value={
              condition.expected_value
                ? dayjs(condition.expected_value, "HH:mm")
                : null
            }
            onChange={(time) =>
              updateCondition({
                expected_value: time ? time?.format("HH:mm") : "",
              })
            }
            className="form-control"
          />
        );

      case "NumberField":
      case "Contact":
        return (
          <input
            type="number"
            className="form-control"
            value={condition?.expected_value}
            onChange={(e) =>
              updateCondition({ expected_value: e.target.value })
            }
          />
        );

      default:
        return (
          <input
            type="text"
            className="form-control"
            value={condition?.expected_value}
            onChange={(e) =>
              updateCondition({ expected_value: e.target.value })
            }
          />
        );
    }
  };

  return (
    <div className="mx-auto bg-white d-flex flex-column flex-wrap rounded-3 border position-relative mb-5 p-4">
      <h5 className="d-flex align-items-center fs-6 m-0 mb-2">
        Prevent Deal Stage Update
        <span
          className="rounded-pill fw-light text-dark badge bg-warning"
          style={{ marginLeft: "8px" }}
        >
          Beta
        </span>
      </h5>

      <hr className="hr" />

      <h5 className="small text-secondary mb-4 mt-2">
        Select the options below that would prevent the deal stage from being
        updated.
      </h5>

      <div className="d-flex align-items-end gap-3 bg-color-1 border p-3 justify-content-between rounded-2">
        {/* Select Field Section */}
        <div className="col">
          <label htmlFor="selectField" className="form-label">
            Select Field
          </label>
          <select
            id="selectField"
            className="form-select"
            value={condition?.field_id}
            onChange={(e) =>
              updateCondition({ field_id: e.target.value, expected_value: "" })
            }
          >
            <option value="">Select</option>
            {GetFormData?.form_fields
  ?.filter(
    (field) =>
      field?.type !== "FreeText" && 
      field?.type !== "Headings" &&
      field?.elementid !== "InputField-opp-key" &&
      field?.elementid !== "InputField-Follow-up-boss-lead-id" // Exclude specific elementids
  )
  ?.map((field) => {
    const getTextFromHTML = (htmlString) => {
      const tempDiv = document.createElement("div");
      tempDiv.innerHTML = htmlString;
      return tempDiv?.textContent || tempDiv?.innerText || "";
    };

    const displayText = getTextFromHTML(field?.settings?.label || field?.name);

    return (
      <option key={field?.elementid} value={field?.elementid}>
        {displayText}
      </option>
    );
  })}

          </select>
        </div>
        {/* Dynamic Field Input Section */}
        <div className="col">
          <label htmlFor="selectOptions" className="form-label">
            Enter Value
          </label>
          {renderFieldInput()}
        </div>
      </div>

      {/* Save Button */}
      <div className="mt-4">
        <button type="button" className="btn btn-primary px-4" onClick={handleSave}>
          {loading ? "Saving..." : "Save"}
        </button>
        {saveMessage && (
          <p className={`mt-3 ${error ? "text-danger" : "text-success"}`}>
            {saveMessage}
          </p>
        )}
      </div>
    </div>
  );
};
