import { gql } from '@apollo/client';

export const GET_FORM_TEMPLATES = gql`
  query GetFormTemplates {
    GetFormTemplates {
      statusCode
      message
      data
    }
  }
`;

export const GET_FUB_DATA = gql`
  query GetFubAllData($type: String!) {
    GetFubAllData(type: $type) {
      output
      status
      info
    }
  }
`;
