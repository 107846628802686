import { useContext, useEffect, useState } from "react";
import { TimePicker } from "antd";
import dayjs from "dayjs";
import { useLocation } from "react-router-dom";
import { boardConetxt } from "../../../pages/form-builder/dashboard/ContextHelper";
import GetStyleClasses from "./BoardElementsSettingsUi/GetStyleClasses";
import { Controller, useFormContext } from "react-hook-form";

export const TimeUi = ({
  elementid,
  oppFields,
  showHide,
  previewFlag = false,
}) => {
  const { board, setboard } = useContext(boardConetxt);
  const [val, setval] = useState(null); // Local state for TimePicker value
  const disableElementForBuild = window.location.pathname.includes("build");
  const initialStyle = {
    display: showHide === "show" ? "none" : "block",
  };

  const {
    formState: { errors },
    control,
    setValue,
  } = useFormContext();

  const Location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(Location.search);
    const updatedParamValue = params.get(elementid);

    if (elementid && updatedParamValue) {
      setValue(elementid, updatedParamValue); // Update React Hook Form value
      setval(dayjs(updatedParamValue, "HH:mm a")); // Parse and set TimePicker value
    }
  }, [Location.search, elementid, setValue]);

  const ElementSettingsData = board?.find(
    (item) => String(item?.elementid) === String(elementid)
  )?.settings;

  const { label, instructions, required, hidden } = ElementSettingsData || {};
  const format = "hh:mm A"; // 12-hour format with AM/PM

  // Sync board state when `val` changes
  useEffect(() => {
    const updatedBoard = board?.map((item) =>
      String(item?.elementid) === String(elementid)
        ? { ...item, settings: { ...item.settings, Time: val?.format(format) } }
        : item
    );
    setboard(updatedBoard);
  }, [val]);
  const ElementName = board.find((item) => item?.elementid === elementid).name;

  const checkingValidation = () => {
    if (Boolean(required) === true && String(showHide) === "show") {
      return false;
    } else if (Boolean(required) === false && String(showHide) === "show") {
      return false;
    } else if (Boolean(required) === true && String(showHide) === "hide") {
      return false;
    } else if (Boolean(required) === false && String(showHide) === "hide") {
      return false;
    } else if (Boolean(required) === true && hidden === true) {
      return false;
    } else if (Boolean(required) === true) {
      return true;
    } else {
      return false;
    }
  };

  const normalizeFieldName = (name) => name?.replace(/[.\s]/g, "_");
  // console.log(" elementid", hidden);

  return (
    <div
      className={`${(previewFlag && GetStyleClasses(elementid)) || ""} ${
        hidden && previewFlag ? "d-none" : ""
      }`}
      style={initialStyle}
      id={elementid}
      hidden={hidden && previewFlag}
    >
      <label>
        <span className="d-flex mb-2 align-items-center">
          <i className="iconify me-1 mr-1 fs-5" data-icon="typcn:time" />
          <span>
            {label === undefined || label === "" ? ElementName : label}

            {required ? <span style={{ color: "red" }}>*</span> : ""}
          </span>
        </span>
      </label>
      <div className="form-floating form-group">
        <Controller
          name={normalizeFieldName(elementid)}
          control={control}
          rules={{
            required: {
              value: checkingValidation(),
              message: "This Field is Required",
            },
          }}
          render={({ field }) => (
            <TimePicker
  className="form-control input-bn"
  format={format}
  use12Hours
  disabled={disableElementForBuild}
              value={field.value ? dayjs(field.value, format) : val} // Sync with React Hook Form or local state
  onChange={(date, dateString) => {
                field.onChange(dateString); // Update React Hook Form value
                setValue(elementid, dateString); // Update React Hook Form
                setval(date); // Update local state
  }}
  id={normalizeFieldName(elementid)}
  defaultValue={oppFields ? dayjs(oppFields, format) : val}
/>
          )}
        />
      </div>
      <small className="text-secondary">{instructions}</small>
      {errors[elementid] && (
        <span className="text-danger">{errors[elementid]?.message}</span>
      )}
    </div>
  );
};
