import React from "react";

const FormFields = ( { newForm, inputHandler } ) => (
    <>
        <div className="mb-4">
            <div className="form-floating w-100 form-group">
                <input
                    placeholder="Workspace title"
                    className="form-control input-bn"
                    type="text"
                    required
                    maxLength={70}
                    name="name"
                    value={newForm.name}
                    onChange={inputHandler}
                />
                <label>
                    <span className="d-flex align-items-center">
                        <i className="iconify me-1 mr-1 fs-5" data-icon="ri:flow-chart" />
                        <span>Form Name</span>
                    </span>
                </label>
            </div>
        </div>

        <div className="mb-4">
            <div className="form-floating w-100 form-group">
                <textarea
                    placeholder="Description"
                    className="form-control input-bn"
                    type="text"
                    maxLength={100}
                    name="description"
                    value={newForm.description}
                    onChange={inputHandler}
                />
                <label>
                    <span className="d-flex align-items-center">
                        <i className="iconify me-1 mr-1 fs-5" data-icon="mdi:text" />
                        <span>Form Description</span>
                    </span>
                </label>
            </div>
        </div>
    </>
);

export default FormFields;
