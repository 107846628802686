import { useState } from "react";
import { ModalFooter } from "./ModalFooter";

const HeadingsSettings = ({
  elementid,
  handleCloseModalClick,
  elementSettingsData,
  maxLengthofLabel,
  board,
}) => {
  const ElementName = board.find(
    (item) => String(item?.elementid) === String(elementid)
  )?.name;
  const initialvalue = {
    label: ElementName,
    size: "left",
    inputType: "text",
    required: false,
    // duplicate: true,
    minValue: "",
    maxValue: "",
    instructions: "",
  };

  const [Headings, setHeadings] = useState(
    Object.keys(elementSettingsData)?.length !== 0
      ? elementSettingsData
      : initialvalue
  );

  const handleInputChange = (e) => {
    const name = e.target.name;
    let value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    if (String(name) === "label" && value.trim() === "") {
      value = ElementName; // Set the default value to the element name
    }
    setHeadings({ ...Headings, [name]: value });
  };

  return (
    <>
      <div className="modal-body py-4 px-lg-5 p-md-4">
        <h5 className="mb-4">
          <i
            className="iconify fs-5 pointer me-1"
            data-icon="tabler:checkbox"
          />
          Field Settings
        </h5>

        <div className="mb-4">
          <label>
            <span className="d-flex mb-2 align-items-center">
              <span>Form Title</span>
            </span>
          </label>
          <input
            placeholder="Placeholder for your Form Title"
            defaultValue={
              Headings?.label === "" ? ElementName : Headings?.label
            }
            name="label"
            onBlur={handleInputChange}
            className="form-control input-bn"
            type="text"
            maxLength={maxLengthofLabel}
          />
          {/* placeholder for errors */}
        </div>

        <hr className="hr my-5" />
        <h5 className="mb-4">
          <i className="iconify fs-5 pointer me-1" data-icon="bx:slider" />
          General Settings
        </h5>
        {/* dropdowns----------- */}
        <div className="mt-4">
          <div className="mb-4">
            <label className="w-100">
              <span className=" mb-2 d-flex justify-content-between align-items-center">
                <span className="me-2">Size</span>
              </span>
            </label>
            <select
              disabled="true"
              className="form-select input-bn"
              value={Headings?.size || ""}
              name="size"
              onChange={handleInputChange}
            >
              <option value="large">Large</option>
            </select>
          </div>
        </div>
      </div>
      <ModalFooter
        ElementSettingData={Headings}
        handleCloseModalClick={handleCloseModalClick}
        elementid={elementid}
      />
    </>
  );
};

HeadingsSettings.propTypes = {};

export default HeadingsSettings;
