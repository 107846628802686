import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import logo from "../../../../assets/images/LOGO-BLUE.png";
import { Loaderr } from "../../../../components/Loaderr";
import { ToastContainer } from "react-toastify";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FORM_TYPES, AppointmentFieldValue, AppointmentFieldOption, leadformType, fieldOptions, templateOptions, templateTypeOptions } from "utils/constants";
import { GET_FORM_TEMPLATES, GET_FUB_DATA } from "graphql/queries/formQueries";
import { CREATE_FORM } from "graphql/mutations/formMutations";
import { prepareFormData } from "utils/formUtils";
import FormFields from "./FormFields";
import SelectField from "./SelectField";
import PrimaryFields from "./PrimaryFields";
import CheckboxField from "./CheckboxField";
import { GET_TEAM_SETTINGS } from "graphql/queries/teamSettingsQueries";

function CreateForm () {
  const [ selectedFields, setSelectedFields ] = useState( [] );

  const [ newForm, setNewForm ] = useState( {
    name: "",
    description: "",
    form_template: "",
    useTemplate: "",
    form_type: "",
    pipelineName: "",
    StageName: "",
    Appointmentform: "no",
    leadformType: "",
    is_form_template: false,
    template_type: "",
  } );
  const navigate = useNavigate();
  const [ pipelineData, setPipelineData ] = useState( [] );
  const [ filteredTemplates, setFilteredTemplates ] = useState( [] );

  const { data: templatesData, loading: templatesLoading, error: templatesError } = useQuery( GET_FORM_TEMPLATES, {
    fetchPolicy: "cache-and-network",
  } );

  const { data, loading, error } = useQuery(GET_TEAM_SETTINGS, {
    fetchPolicy: "cache-and-network",
  });

  const allowToManageTemplate = data?.GetTeamSettings[0]?.allow_to_manage_template ?? false;

  useEffect(() => {
    if (templatesData?.GetFormTemplates?.data) {
      const filteredTemplates = templatesData.GetFormTemplates.data.filter(
        (template) => template.default_template_visibility === true
      );
      setFilteredTemplates(filteredTemplates);
    }
  }, [templatesData]);
  
  console.log( "filteredTemplates", filteredTemplates, "templatesData", templatesData );

  const { data: GetingAllFubDataList } = useQuery( GET_FUB_DATA, {
    variables: {
      type: "pipelines"
    },
  } )

  useEffect( () => {
    if ( GetingAllFubDataList ) {
      setPipelineData( GetingAllFubDataList.GetFubAllData.output )
    }
  }, [ GetingAllFubDataList ] )


  const [ creatForm, { loading: CreateFormLoading } ] = useMutation( CREATE_FORM, {
    onError: ( error ) => {
      toast.error( 'Failed To Fetch , Form Not Created ', {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
        // transition: Bounce,
      } );
    },

    onCompleted: ( data ) => {
      const form_id = data.CreateForm.form_id;
      navigate( `/forms/build/${ form_id }` );
    },
  } );

  const [ assignedAgent, setAssignedAgent ] = useState( '' );

  const handleAssignedAgentChange = useCallback( ( e ) => {
    setAssignedAgent( e.target.value );
  }, [] );

  const pipelineOptions = pipelineData?.map( ( item ) => ( {
    label: item.name,
    value: item.id
  } ) ) || [];

  const [ stageOptions, setStageOptions ] = useState( [] );

  useEffect( () => {
    const selectedPipeline = pipelineData?.find(
      ( pipeline ) => +pipeline.id === +newForm.pipelineName
    );

    if ( selectedPipeline ) {
      setStageOptions(
        selectedPipeline.stages.map( ( stage ) => ( {
          label: stage.name,
          value: stage.id
        } ) )
      );
    } else {
      setStageOptions( [] );
    }
  }, [ newForm.pipelineName, pipelineData ] );

  const appointmentOptions = AppointmentFieldValue.map( ( item ) => ( {
    label: item.name,
    value: item.value
  } ) );

  const formTemplateOptions = filteredTemplates.map( ( template ) => ( {
    label: template.form_name,
    value: template.form_id,
  } ) );

  const inputHandler = ( e ) => {
    e.preventDefault();
    const name = e.target.name;
    const value = e.target.value;
    setNewForm( { ...newForm, [ name ]: value } );
  };

  useEffect( () => {
    if ( newForm.useTemplate === "No" ) {
      setNewForm( ( prevForm ) => ( { ...prevForm, form_template: "" } ) );
    }
    if ( newForm.useTemplate == 'Yes' ) {
      setSelectedFields( [] )
    }
  }, [ newForm.useTemplate ] );

  const handleSubmit = ( e ) => {
    e.preventDefault();
    const formData = prepareFormData( newForm, selectedFields, assignedAgent );
    creatForm( { variables: { formData } } );
  };

  const handleFieldChange = ( e, item ) => {
    const isChecked = e.target.checked;

    if ( isChecked ) {
      setSelectedFields( [ ...selectedFields, item.elementid ] );
    } else {
      setSelectedFields(
        selectedFields.filter( ( fieldName ) => fieldName !== item.elementid )
      );
    }
  };

  if ( CreateFormLoading ) return <Loaderr />;
  return (
    <>
      {/* {CreateFormLoading && <Loaderr />} */}
      <ToastContainer />

      <header className="header bg-white border-bottom" id="header">
        <div>
          <div className="pointer" onClick={() => navigate( "/forms/" )}>
            <div className="d-flex align-items-center ms-2">
              <span className="navbar-brand m-0 p-0">
                <img className="img-fluid" src={logo} width="30" alt="" />
              </span>
              <i className="bx bx-chevron-left nav_icon mx-md-2 mx-1"></i>
              <span className="p-0 m-0 text-decoration-underline">Form Dashboard</span>
            </div>
          </div>
        </div>
      </header>
      <div className="main container mx-auto" id="body-pd">
        <div
          className="d-lg-none alert alert-warning banner-top p-3 py-2 rounded-0 m-0"
          role="alert"
        >
          <div className="d-flex flex-wrap align-items-center">
            <div className="d-flex align-items-center my-2">
              <i className="bx bx-mobile fs-5" />
              <i className="bx bx-arrow-to-right me-2 fs-5" />
              <i className="bx bx-desktop me-2 fs-5" />
            </div>
            <span className="my-2">
              Please switch to desktop and optimize this view. Smaller screens
              are not efficient.
            </span>
          </div>
        </div>
        <section className="col-xl-7 mx-auto">
          <div className="bg-white p-4 rounded-3 border">
            <h5>New Form</h5>
            <p className="text-secondary mt-2">
              Add a title and description for your form
            </p>
            <form className="mt-lg-5 mt-4" action="" onSubmit={handleSubmit}>
              <FormFields newForm={newForm} inputHandler={inputHandler} />

              <SelectField
                label="Select Form Type"
                icon="mdi:text"
                name="form_type"
                value={newForm.form_type}
                options={FORM_TYPES}
                onChange={inputHandler}
                required
              />

              {newForm.form_type === "Opportunity Forms" && (
                <div className="mb-4">
                  <div className="form-floating w-100 form-group">
                    <select
                      className="form-select input-bn"
                      placeholder="Please Select"
                      name="assignedAgent"
                      value={assignedAgent} // Step 3: Connect state to the input
                      onChange={handleAssignedAgentChange} // Step 3: Connect onChange to the input
                      required
                    >
                      <option value="" disabled selected hidden>Select an option</option>
                      <option value="fub_assigned_agent">Use The assigned agent on the FUB lead record</option>
                      <option value="assigned_agent_field">Use an Assigned agent field to assign the FUB lead and Deal.</option>
                    </select>
                    <label>
                      <span className="d-flex align-items-center">
                        <i
                          className="iconify me-1 mr-1 fs-5"
                          data-icon="mdi:text"
                        />
                        <span>
                          Assigned agents
                        </span>
                      </span>
                    </label>

                    <div className="d-flex justify-content-end">
                      <div className="tooltip-container">
                        <i className="iconify text-secondary fs-5" data-icon="mdi:info" />
                        <div className="tooltip-text text-start form-tooltip p-3">
                          Use The assigned agent on the FUB lead record means when the form is submitted
                          we will use the assigned agent on the lead record as the assigned agent on the deal record.<br /> <br />
                          Use an Assigned agent field to assign the FUB lead and Deal.
                          means whichever agent is selected on the Outside agent field will be assigned to the lead record and the deal record.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {newForm.form_type === "Opportunity Forms" && <SelectField
                label="Select pipeline"
                icon="mdi:text"
                name="pipelineName"
                value={newForm.pipelineName}
                options={pipelineOptions}
                onChange={inputHandler}
                required
              />}

              {newForm.form_type === "Opportunity Forms" && (
                <SelectField
                  label="Select Stage"
                  icon="mdi:text"
                  name="StageName"
                  value={newForm.StageName}
                  options={stageOptions}
                  onChange={inputHandler}
                  required
                />
              )}

              {newForm.form_type === "Opportunity Forms" && (
                <SelectField
                  label="Is this an appointment form?"
                  icon="mdi:text"
                  name="Appointmentform"
                  value={newForm.Appointmentform}
                  options={appointmentOptions}
                  onChange={inputHandler}
                  required
                />
              )}
              {
                newForm.form_type === "Lead Forms" && (
                  <div className="mb-4">
                    <div className="form-floating w-100 form-group">
                      <select
                        className="form-select input-bn"
                        placeholder="Please Select"
                        onChange={inputHandler}
                        name="leadformType"
                        defaultValue={newForm.leadformType}
                      >
                        <option value="" disabled hidden>
                          Choose Lead Form Type
                        </option>
                        {leadformType.map( ( item ) => (
                          <option key={item.id} value={item.value}>
                            {item.name}
                          </option>
                        ) )}
                      </select>

                      <label>
                        <span className="d-flex align-items-center">
                          <i
                            className="iconify me-1 mr-1 fs-5"
                            data-icon="mdi:text"
                          />
                          <span>Lead Form Type</span>
                        </span>
                      </label>

                      <div className="d-flex justify-content-end">
                        <div className="tooltip-container">
                          <i
                            className="iconify text-secondary fs-5"
                            data-icon="mdi:info"
                          />
                          <div className="tooltip-text text-start form-tooltip p-3">
                            {
                              leadformType.find(
                                ( item ) => item.value === newForm.leadformType
                              )?.iconInfo || "Please select a lead form type."
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              }



              {newForm.form_type === "Opportunity Forms" && (
                <>
                  {/* Use Template Selection */}
                  <SelectField
                    label="Do You Want to Use Templates?"
                    icon="mdi:text"
                    name="useTemplate"
                    value={newForm.useTemplate}
                    options={templateOptions}
                    onChange={inputHandler}
                    required
                  />

                  {newForm.useTemplate === "No" && newForm.Appointmentform === "no" && (
                    <PrimaryFields
                      title="Primary Fields"
                      description="Select primary fields you want to pre-add to your form."
                      options={fieldOptions}
                      selectedFields={selectedFields}
                      handleFieldChange={handleFieldChange}
                    />
                  )}

                  {newForm.useTemplate === "Yes" && (
                    <SelectField
                      label="Choose Template"
                      icon="mdi:text"
                      name="form_template"
                      value={newForm.form_template}
                      options={formTemplateOptions}
                      onChange={inputHandler}
                      required
                    />
                  )}
                </>
              )}
              {allowToManageTemplate && <CheckboxField
                id="is_form_template"
                name="is_form_template"
                checked={newForm.is_form_template}
                onChange={( e ) =>
                  setNewForm( { ...newForm, is_form_template: e.target.checked } )
                }
                label="Mark as a Form Template"
              />}

              {allowToManageTemplate && newForm.is_form_template && (
                <SelectField
                  label="Choose Template Type"
                  icon="mdi:text"
                  name="template_type"
                  value={newForm.template_type}
                  options={templateTypeOptions}
                  onChange={inputHandler}
                  required
                />
              )}

              <div className="d-flex justify-content-end">
                <button

                  type="submit"
                  className="d-flex align-items-center btn btn-primary"
                >
                  Continue
                </button>
              </div>
            </form>
          </div>
        </section>
      </div>
    </>
  );
}

export default CreateForm;
