import React, { useEffect, useRef } from "react";
import { gql, useMutation } from "@apollo/client";
import { useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";

const getFubData = gql`
  query GetFubAllData($type: String!) {
    GetFubAllData(type: $type) {
      output
      status
      info
    }
  }
`;
const GetForms = gql`
  query GetDispForms {
    getDispForms {
      form_name
      form_id
    }
  }
`;
const UPDATE_FORM_SETTINGS = gql`
  mutation UpdateFormSettings(
    $teamId: String!
    $formId: String
    $isThisApptForm: String
    $dispText: String
    $selectDispForm: String
    $dispSchedHour: String
    $dispSchedMinutes: String
    $dispReminderHour: String
    $dispReminderMinutes: String
    $makeReminderRec: Boolean
    $pipeline: String
    $stage: String
    $defaultTemplateVisibility: Boolean
  ) {
    UpdateFormSettings(
      team_id: $teamId
      form_id: $formId
      is_this_appt_form: $isThisApptForm
      disp_text: $dispText
      select_disp_form: $selectDispForm
      disp_sched_hour: $dispSchedHour
      disp_sched_minutes: $dispSchedMinutes
      disp_reminder_hour: $dispReminderHour
      disp_reminder_minutes: $dispReminderMinutes
      make_reminder_rec: $makeReminderRec
      pipeline: $pipeline
      stage: $stage
      default_template_visibility: $defaultTemplateVisibility
    ) {
      team_id
      form_id
    }
  }
`;
const AppointmentFieldValue = [
  {
    id: 1,
    name: "Yes appointment form",
    value: "yes_appt_form",
  },
  {
    id: 2,
    name: "Yes appointment disposition form",
    value: "yes_disp_form",
  },
  {
    id: 3,
    name: "No",
    value: "no",
  },
];
export const SettingPageForOpportunityForm = ({
  GetFormData,
  setFormLoader,
  setDealStage
}) => {
  const params = useParams();

  const [FormEntryData, setFormEntryData] = React.useState({
    pipelineName: "",
    StageName: "",
    Appointmentform: "",
    selectDispForm: "",
    dispositionText: "",
    dispSchedTime: { hours: "", minutes: "" },
    dispReminderTime: { hours: "", minutes: "" },
    isRecurring: false,
    showScheduleReminder: false,
    showTemplate: false,
  });
  const [updateFormSetting] = useMutation(UPDATE_FORM_SETTINGS);
  console.log("GetFormData",FormEntryData,GetFormData)
  useEffect(() => {
    if (GetFormData) {
      setFormEntryData((prev) => ({
        ...prev,
        pipelineName: GetFormData?.pipeline,
        StageName: GetFormData?.stage,
        Appointmentform: GetFormData?.is_this_appt_form,
        selectDispForm: GetFormData?.select_disp_form,
        dispositionText: GetFormData?.disp_text,
        dispSchedTime: {
          hours: GetFormData?.disp_sched_hour,
          minutes: GetFormData?.disp_sched_minutes,
        },
        dispReminderTime: {
          hours: GetFormData?.disp_reminder_hour,
          minutes: GetFormData?.disp_reminder_minutes,
        },
        isRecurring: GetFormData?.make_reminder_rec,
        showScheduleReminder:
          GetFormData?.disp_reminder_hour || GetFormData?.disp_reminder_minutes,
        showTemplate: GetFormData?.default_template_visibility || "false",
      }));
    }
  }, [GetFormData]);

  // Generic handler for changes in input/select fields
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    
    if (String(name) === "pipelineName") {
      fubData?.GetFubAllData?.output?.map((item, index) => {
        if (String(item?.id) === String(value)) {
          setFormEntryData((prev) => ({
            ...prev,
            pipelineName: value,
            StageName: item.stages[0].id,
          }));
          return item; // Add return statement
        }
        return null; // Add return statement for other cases
      });
    }
    setFormEntryData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  //console.log("FormEntryData",FormEntryData)
  // Specific handlers for nested objects
  const handleNestedChange = (e, key) => {
    const { name, value } = e.target;
    setFormEntryData((prev) => ({
      ...prev,
      [key]: {
        ...prev[key],
        [name]: value,
      },
    }));
  };
  const handleCheckboxChange = () => {
    setFormEntryData((prev) => ({
      ...prev,
      isRecurring: !prev.isRecurring,
    }));
  };

  const { data: fubData } = useQuery(getFubData, {
    variables: {
      type: "pipelines",
    },
  });

  const { data: GetFormsData } = useQuery(GetForms,{
    skip: FormEntryData?.Appointmentform !== "yes_disp_form",
  });
  const isInitialRender = useRef(true);
  const previousFormDatas = useRef({});

  useEffect(() => {
    if (!FormEntryData) return; // Prevent errors if FormEntryData is undefined or null

  setDealStage(FormEntryData?.Appointmentform);

  if (isInitialRender.current) {
    isInitialRender.current = false;
    previousFormDatas.current = FormEntryData || {}; // Ensure it initializes with an object
    return;
  }

  // Compare current form data with the previous form data
  const hasChanged = Object.keys(FormEntryData || {}).some((key) => {
    const currentValue = FormEntryData[key];
    const previousValue = previousFormDatas.current?.[key];

    // Handle nested objects like dispSchedTime and dispReminderTime
    if (
      typeof currentValue === "object" &&
      currentValue !== null &&
      typeof previousValue === "object" &&
      previousValue !== null
    ) {
      return Object.keys(currentValue).some(
        (nestedKey) => currentValue[nestedKey] !== previousValue[nestedKey]
      );
    }
    return currentValue !== previousValue;
  });

    if (hasChanged) {
      setFormLoader(true);
      updateFormSetting({
        variables: {
          teamId: String(GetFormData?.team_id),
          formId: params?.id,
          pipeline: String(FormEntryData?.pipelineName),
          stage: String(FormEntryData?.StageName),
          isThisApptForm: FormEntryData?.Appointmentform,
          dispText: FormEntryData?.dispositionTex ,
          selectDispForm: FormEntryData?.selectDispForm,
          dispSchedHour: FormEntryData?.dispSchedTime.hours || null,
          dispSchedMinutes: FormEntryData?.dispSchedTime.minutes || null,
          dispReminderHour: FormEntryData?.dispReminderTime.hours || null,
          dispReminderMinutes: FormEntryData?.dispReminderTime.minutes || null,
          makeReminderRec: FormEntryData?.isRecurring,
          defaultTemplateVisibility: FormEntryData?.showTemplate === "true" ? true : false,

        },
      })
        .then(() => {
          previousFormDatas.current = { ...FormEntryData }; // Update previous state
        })
        .finally(() => {
          setFormLoader(false);
        });
    }
  }, [FormEntryData]);

  return (
    <React.Fragment>
      {/* Select Pipeline */}
      <div className="mb-3">
        <label className="mb-2 d-flex align-items-center">
          <i
            className="iconify fs-6 me-2 nav_icon"
            data-icon="fa6-solid:house-user"
          />
          Select Pipeline
        </label>
        <select
          className="form-control form-select input-bn search-fgy mb-3"
          onChange={(e) => handleInputChange(e)}
          name="pipelineName"
          value={FormEntryData?.pipelineName}
        >
          <option value="" disabled hidden>
            Choose
          </option>
          {fubData?.GetFubAllData?.output?.map((item, index) => (
            <option key={item?.id} value={item?.id}>
              {item?.name}
            </option>
          ))}
        </select>
      </div>

      {/* Select Stage */}

      <div className="mb-4">
        <label className="mb-2 d-flex align-items-center">
          <i
            className="iconify fs-6 me-2 nav_icon"
            data-icon="fa6-solid:bars-staggered"
          />
          Select stage
        </label>
        <select
          className="form-control form-select input-bn search-fgy mb-3"
          onChange={(e) => handleInputChange(e)}
          name="StageName"
          value={FormEntryData?.StageName}
        >
          <option value="" disabled hidden>
            Choose
          </option>
          {fubData?.GetFubAllData?.output?.map((item, index) => {
            if (String(item.id) === String(FormEntryData?.pipelineName)) {
              return item?.stages?.map((items, index) => {
                return (
                  <option key={items?.id} value={items?.id}>
                    {items?.name}
                  </option>
                );
              });
            }
            return null; // Add return statement for other cases
          })}
        </select>
      </div>

    { GetFormData?.is_form_template === true && <div className="mb-4">
      <div className="form-floating w-100 form-group">
           <select
        className="form-control form-select input-bn search-fgy mb-3"
        onChange={(e) => handleInputChange(e)}
        name="showTemplate"
        value={FormEntryData?.showTemplate ?? false}       
      >
        <option value="false">Hide Template</option>
        <option value="true">Show Template</option>
      </select>
      <label>
      <span className="d-flex align-items-center">

      <i className="iconify fs-6 me-2 nav_icon" data-icon="fa6-solid:eye" />
      <span>Manage Template Visibility?</span>
      </span> 
      </label>
    </div>

    </div>}

      {/* Select User Forms */}
      <div className="mb-5">
        <div className="form-floating w-100 form-group">
          <select
            className="form-select input-bn"
            placeholder="Please Select"
            //onChange={inputHandler}
            onChange={(e) => handleInputChange(e)}
            name="Appointmentform"
            value={FormEntryData?.Appointmentform}
          >
            <option value="" disabled hidden>
              Choose
            </option>

            {AppointmentFieldValue?.map((item, index) => {
              return (
                <option key={item?.id} value={item?.value}>
                  {item?.name}
                </option>
              );
            })}
          </select>

          <label>
            <span className="d-flex align-items-center">
              <i className="iconify me-1 mr-1 fs-5" data-icon="mdi:text" />
              <span>Is this is an appointment form ?</span>
            </span>
          </label>
        </div>
      </div>

      {/* Select Dispostion form */}
      {FormEntryData?.Appointmentform === "yes_appt_form" && (
        <div className="mb-5">
          <div className="form-floating w-100 form-group">
            <select
              className="form-select input-bn"
              placeholder="Please Select"
              onChange={(e) => handleInputChange(e)}
              name="selectDispForm"
              value={FormEntryData?.selectDispForm}
            >
              <option value="" disabled hidden>
                Choose
              </option>

              {GetFormsData?.getDispForms?.map((item, index) => {
                return (
                  <option key={item?.form_id} value={item?.form_id}>
                    {item?.form_name}
                  </option>
                );
              })}
            </select>

            <label>
              <span className="d-flex align-items-center">
                <i className="iconify me-1 mr-1 fs-5" data-icon="mdi:text" />
                <span>Select Disposition form</span>
              </span>
            </label>
          </div>
        </div>
      )}

      {/* Select Dispostion form settings */}
      <div>
        {FormEntryData?.Appointmentform === "yes_disp_form" && (
          <div className="">
            <div className="w-100 d-flex flex-column m-0 mb-4 pb-2 border-bottom">
              <h5 className="fs-6 m-0 mb-2">
                Disposition Settings{" "}
                <span
                  className="rounded-pill fw-light text-dark badge bg-warning"
                  style={{ marginLeft: "5px" }}
                >
                  {" "}
                  Beta{" "}
                </span>{" "}
              </h5>
              <p className="small text-secondary">
                Set and schedule disposition text sent to your agents.
              </p>
            </div>

            <div className="mb-4 dispo-text">
              <label className="mb-2">
                <span>Disposition Text</span>
              </label>
              <div className="pre-dispo">
                <p className="m-0">
                  Hey! This is the link to your Disposition Form: URL for your
                  appointment ID: with contact_name
                </p>
              </div>

              <textarea
                type="text"
                className="form-control input-bn"
                id="floatingInput"
                name="dispositionText"
                value={FormEntryData?.dispositionText}
                onChange={(e) => handleInputChange(e)}
                placeholder="“Your text can go here”"
                maxLength={150}
              ></textarea>
            </div>

            <div className="w-100 d-flex flex-column m-0">
              <h5 className="fs-6 m-0 mb-2">
                <i
                  className="iconify fs-6 me-2 nav_icon"
                  data-icon="fa6-solid:clock"
                ></i>
                Schedule timer
              </h5>
              <p className="small text-secondary">
                The disposition text will be sent based on the timer you set,
                with the countdown starting once the appointment begins.
              </p>

              <div className="d-flex flex-wrap gap-3">
                <div className="mb-3 col-lg-4 col">
                  <input
                    type="text"
                    className="form-control input-bn mb-3"
                    placeholder="Hours"
                    name="hours"
                    value={FormEntryData?.dispSchedTime?.hours || ""}
                    onChange={(e) => {
                      handleNestedChange(e, "dispSchedTime");
                    }}
                    maxLength="2" // Limit input to 2 digits
                  />
                </div>
                <div className="mb-3 col-lg-4 col">
                  <input
                    type="text"
                    className="form-control input-bn mb-3"
                    placeholder="Minutes"
                    name="minutes"
                    value={FormEntryData?.dispSchedTime?.minutes || ""} 
                    onChange={(e) => {
                      handleNestedChange(e, "dispSchedTime");
                    }}
                    maxLength="2" // Limit input to 2 digits
                  />
                </div>
              </div>

              {/* Button to schedule the timer */}
            </div>

            <hr className="m-0 mb-4" />

            {/* Add Schedule Reminder Button */}
            {!FormEntryData?.showScheduleReminder && (
              <button
                className="btn btn-primary mb-3"
                onClick={() => {
                  setFormEntryData((prev) => ({
                    ...prev,
                    showScheduleReminder: true,
                  }));
                }}
              >
                Add Schedule Reminder
              </button>
            )}

            {/* Schedule Reminder Section */}
            {FormEntryData?.showScheduleReminder && (
              <div className="w-100 d-flex flex-column m-0">
                <div className="d-flex justify-content-between">
                  <h5 className="fs-6 m-0 mb-2">
                    <i
                      className="iconify fs-6 me-2 nav_icon"
                      data-icon="fa6-solid:clock"
                    ></i>
                    Schedule reminder
                  </h5>
                  {/* Trash Icon to delete the reminder */}
                  <span
                    className=""
                    onClick={() => {
                      setFormEntryData((prev) => ({
                        ...prev,
                        showScheduleReminder: false,
                        dispReminderTime: { hours: "", minutes: "" },
                        isRecurring: false,
                      }));
                    }}
                  >
                    <i
                      className="iconify fs-6 me-2 nav_icon text-danger"
                      data-icon="fa6-solid:trash"
                    ></i>
                  </span>
                </div>

                <p className="small text-secondary">
                  To be sent if disposition form has not been filled even after
                  disposition text has been sent.
                </p>

                <div className="d-flex flex-wrap gap-3">
                  <div className="mb-3 col-lg-4 col">
                    <input
                      type="text"
                      className="form-control input-bn mb-3"
                      placeholder="Reminder Hours"
                      name="hours"
                      value={FormEntryData?.dispReminderTime?.hours || ""}
                      onChange={(e) => {
                        handleNestedChange(e, "dispReminderTime");
                      }}
                      maxLength="2" // Limit input to 2 digits
                    />
                  </div>
                  <div className="mb-3 col-lg-4 col">
                    <input
                      type="text"
                      className="form-control input-bn mb-3"
                      placeholder="Reminder Minutes"
                      name="minutes"
                      value={FormEntryData?.dispReminderTime?.minutes || ""}
                      onChange={(e) => {
                        handleNestedChange(e, "dispReminderTime");
                      }}
                      maxLength="2" // Limit input to 2 digits
                    />
                  </div>
                </div>

                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value={FormEntryData?.isRecurring}
                    checked={FormEntryData?.isRecurring}
                    id="flexCheckDefault"
                    onChange={handleCheckboxChange}
                  />
                  <label
                    className="form-check-label small"
                    htmlFor="flexCheckDefault"
                  >
                    Make reminder recurring
                  </label>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </React.Fragment>
  );
};
