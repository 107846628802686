import React from "react";

const SelectField = ( { label, icon, name, value, options, onChange, required } ) => (
    <div className="mb-4">
        <div className="form-floating w-100 form-group">
            <select className="form-select input-bn" name={name} value={value} onChange={onChange} required={required}>
                <option value="">Choose...</option>
                {options.map( ( item, index ) => (
                    <option key={index} value={item.value}>{item.label}</option>
                ) )}
            </select>
            <label>
                <span className="d-flex align-items-center">
                    <i className="iconify me-1 mr-1 fs-5" data-icon={icon} />
                    <span>{label}</span>
                </span>
            </label>
        </div>
    </div>
);

export default SelectField;
