import { useState, useEffect, useContext, useCallback, useRef } from "react";
import { Droppable } from "react-beautiful-dnd";
import DragableElement from "../../../components/DragableElement";
import { ElementsUIGenrate } from "../../../components/ElementsUIGenrate";
import { ElemContext, boardConetxt } from "./ContextHelper";
import fublogo from "../../../assets/images/fub-circle.png";

import { FormPlanContext } from "./ContextHelper";



const FormBuilderRightSideBar = ({
  formType,
  setCustomEleDeleteId,
  teamId,
  isCollapsed,
  setIsCollapsed,
  TeamFields,
  loading,
  refetch
}) => {
  const { board } = useContext(boardConetxt);
  const { formPlan } = useContext(FormPlanContext);
  const {
    CustomElement,
    setCustomElement,
    defaultElement,
    setDefaultElement,
    primary,
    setPrimary,
  } = useContext(ElemContext);
  // //console.log("TeamFields", TeamFields);
  const [value, setValue] = useState("");
  const [elementDisabled, setElementDisabled] = useState(false);
  const [showfound, setShowFound] = useState(false);
 

  const isFirstRun = useRef(true); // Track the first run

  useEffect(() => {
    if (
      !TeamFields ||
      TeamFields.length === 0
    ) {
      return; // Exit if there's no data
    }

    // Prevent running on the first render unless data is updated
    if (isFirstRun.current && TeamFields?.length !== 0) {
      isFirstRun.current = false; // Mark the first run as completed
    }

    // Initialize lists
    const defaultList = [];
    const customList = [];
    const primaryList = [];

    // Create a map of elements present on the board for quick lookup
    const boardElementMap = new Map(
      board?.map((item) => [item?.elementid, item])
    );

// Process form fields
TeamFields?.forEach((item) => {
  const fieldType = formType?.split(" ")[0]?.toLowerCase();
  const word = item?.field_id?.replace(/_/g, " ");
  
  // Define the object to be used
  const obj = {
    elementid: item?.field_id,
    name: item?.input_type === "FreeText" ? item?.input_type : (item?.field_name == null ? word : item.field_name),
    type: item?.input_type || "InputField",
    settings: item?.settings || {},
  };

  // Add to appropriate list based on field type
  if (item.field_sub_type?.toLowerCase() === fieldType) {
    if (item.field_type === "default") {
      defaultList.push(obj);
    } else if (item.field_type === "custom") {
      customList.push(obj);
    } else if (item.field_type === "primary") {
      primaryList.push(obj);
    }
  }
});


    // Function to move elements present on the board to the end of the list
    const updateListWithBoardElements = (list) => {
      const listWithBoardElementsAtEnd = list?.filter(
        (item) => !boardElementMap.has(item?.elementid)
      );
      const boardElements = list?.filter((item) =>
        boardElementMap.has(item?.elementid)
      );
      return [...listWithBoardElementsAtEnd, ...boardElements];
    };

    // Update lists to include elements already on the board at the end
    const updatedDefaultList = updateListWithBoardElements(defaultList);
    const updatedCustomList = updateListWithBoardElements(customList);
    const updatedPrimaryList = updateListWithBoardElements(primaryList);

    // Set state with updated lists
    setPrimary(updatedPrimaryList);
    setDefaultElement(updatedDefaultList);
    setCustomElement(updatedCustomList);

    // Check and set elementDisabled
    setElementDisabled(customList.length >= formPlan?.CustomFieldLimit);
  }, [
    board,
    TeamFields,
    formType,
    formPlan?.CustomFieldLimit,
  ]);
  const prevBoardRef = useRef(board); // Store the previous state of the board
  const hasMounted = useRef(false); // Track if the component has mounted

  useEffect(() => {
    const isBoardChanged = (prevBoard, newBoard) => {
      if (prevBoard?.length !== newBoard?.length) return true;

      for (let i = 0; i < newBoard?.length; i++) {
        if (JSON.stringify(prevBoard[i]) !== JSON.stringify(newBoard[i])) {
          return true;
        }
      }

      return false;
    };

    if (hasMounted.current) {
      // Only check changes after the component has mounted
      if (isBoardChanged(prevBoardRef.current, board)) {
        refetch();
        prevBoardRef.current = board;
      }
    } else {
      hasMounted.current = true; // Set the flag on first render
    }
  }, [board, refetch]);
  

  const filterElements = defaultElement?.filter((item) => {
    return (item?.settings?.label || item?.name || item?.type)
      ?.toLowerCase()
      ?.includes(value?.toLowerCase());
  });

  const filterCustomElements = CustomElement?.filter((item) => {
    return (item?.settings?.label || item?.name || item?.type)
      ?.toLowerCase()
      ?.includes(value?.toLowerCase());
  });
  
  const filterPrimaryElements= primary?.filter((item) => {
    return (item?.settings?.label || item?.name || item?.type)
      ?.toLowerCase()
      ?.includes(value?.toLowerCase());
  });
  useEffect(() => {
   if(filterCustomElements){
    if (filterCustomElements?.length == 0) {
      setShowFound(true);
    } else {
      setShowFound(false);
    }
   }else if(filterElements){
    if (filterElements?.length == 0) {
      setShowFound(true);
    } else {
      setShowFound(false);
    }
    }else if(filterPrimaryElements){
      if (filterPrimaryElements?.length == 0) {
        setShowFound(true);
      } else {
        setShowFound(false);
      }
    }

  }, [filterElements, filterCustomElements,filterPrimaryElements]);


  //////console.log("Forms>>",filterElements,filterCustomElements)
  const handleClick = (e, elementid, ElementName) => {
    e.preventDefault();
    setCustomEleDeleteId({
      elementid: elementid,
      name: ElementName,
      refetchform: refetch,
    });
  };

  let show = true;
  const Element = useCallback(
    ({
      id,
      name,
      type,
      placeholder,
      field_type = "",
      isDisabled = false,
      CustomElement,
      primaryElement,
    }) => {
      const ElementName = placeholder?.label || name || type;

      const str = id?.split("-")[0];
      const Store = ElementsUIGenrate(str);

      return (
        <div
          className={`p-0 ${
            isDisabled &&
            field_type === "default_field" &&
            "disable-default-field-div"
          }`}
        >
          <div
            className={`d-flex p-3 border rounded align-items-center gap-2 fm-ucard ${
              isDisabled &&
              field_type === "default_field" &&
              "disable-default-field"
            }`}
            id={id}
          >
            <div className="me-2">
              <i className="fm-icon iconify" data-icon={Store?.icon} />
            </div>

            <span>{ElementName}</span>

            {CustomElement && (
              <span
                className="ms-auto pointer"
                data-bs-toggle="modal"
                data-bs-target="#CustomFieldDeleteModal"
                onClick={(e) => handleClick(e, id, ElementName)}
              >
                <i className="iconify text-dark" data-icon="ph:trash"></i>
              </span>
            )}

            {CustomElement ? (
              <span className="icon-container">
                <i
                  className="small iconify text-primary"
                  data-icon="fa6-solid:bookmark"
                ></i>
              </span>
            ) : primaryElement ? (
              <span className="icon-container ms-auto">
                <img
                  className="small iconify text-primary"
                  src={fublogo}
                  alt="Logo"
                />
              </span>
            ) : (
              <span className="icon-container ms-auto">
                <i
                  className="small iconify text-primary"
                  data-icon="fa6-solid:cube"
                ></i>
              </span>
            )}
          </div>
        </div>
      );
    },
    []
  );
  //////console.log("isCollapsed",isCollapsed)
  const handleClickmodal = () => {
    //////console.log("isCollapsed",isCollapsed)

    setIsCollapsed(!isCollapsed);
  };

  ////console.log("TabElementId",TeamFieldsLoading,filterElements?.length === 0)
  return (
    <div className="w-100 pb-5 mb-5">
      <div className="mb-4">
        <div className="w-100 d-flex ">
          <input
            placeholder="Search "
            className="form-control col me-2 input-bn search-bn"
            type="search"
            value={value}
            onChange={(e) => setValue(e.target.value)}
            style={{ height: "30px !important" }}
          />

          <div className="tooltip-container">
            <button
              className={`add-bn rounded-circle shadow border ${
                elementDisabled ? "hover-overlay" : null
              }`}
              data-bs-toggle="collapse"
              data-bs-target="#newF"
              disabled={elementDisabled}
            >
              <i className="iconify text-white fs-4" data-icon="mdi:plus"></i>
            </button>
            {elementDisabled && (
              <div className="tooltip-text form-tooltip p-2">
                You have reached the limit of custom fields
              </div>
            )}
          </div>
        </div>
        {value && (
  <p style={{ paddingTop: "15px" }}>
    {filterPrimaryElements?.length || filterElements?.length || filterCustomElements?.length ? (
      `Search found in: ${
        filterPrimaryElements?.length
          ? `Primary Fields (${filterPrimaryElements?.length})`
          : ""
      } ${
        filterElements?.length
          ? `Default Fields (${filterElements?.length})`
          : ""
      } ${
        filterCustomElements?.length
          ? `Custom Fields (${filterCustomElements?.length})`
          : ""
      }`.trim()
    ) : (
      "No results found in any category"
    )}
  </p>
)}



      </div>

      {loading ? (
        <div className="d-flex justify-content-center mt-5 pt-3">
          <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      ) : (
        <div
          className="accordion accordion-flush fm-field-accordion"
          id="accordionFlush"
        >
          <div className="accordion-item">
            <h2 className="accordion-header" id="flush-headingOne">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapse1"
              >
                Primary Fields
              </button>
            </h2>
            <div
              id="flush-collapse1"
              className="accordion-collapse collapse"
              data-bs-parent="#accordionFlush"
            >
              <div className="accordion-body p-0">
                <Droppable droppableId="PrimaryElements">
                  {(provided, index) => (
                    <div
                      key={index}
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                      className="fm-component-cont mt-2 mb-4 row row-cols-1 gap-3 p-0 m-0"
                    >
                      {filterPrimaryElements?.map((item, index) => {
                        //////console.log("item All Default>>", item);
                        const isElementInBoard = board?.some(
                          (element) => element?.elementid === item?.elementid
                        );
                        // setElementDisabled(isElementInBoard)/
                        const CustomElement = false;
                        const primaryElement = true;

                        // const str = item?.elementid?.split("-")[0];
                        //////console.log("item>><",isElementInBoard)
                        // const uuid = Math.floor(Math.random() * 10000);
                        if (isElementInBoard) {
                          return (
                            <div>
                              <Element
                                key={index}
                                id={item?.elementid}
                                type={item?.type}
                                name={item?.name}
                                placeholder={item?.settings}
                                field_type="default_field"
                                isDisabled={isElementInBoard}
                                // CustomElement={CustomElement}
                                primaryElement={primaryElement}
                              />
                            </div>
                          );
                        } else {
                          return (
                            <DragableElement
                              id={item?.elementid}
                              key={item?.elementid} //if we pass key here then it will give error and drag and drop will not work
                              index={index}
                              element={
                                <Element
                                  id={item?.elementid}
                                  type={item?.type}
                                  name={item?.name}
                                  placeholder={item?.settings}
                                  field_type="default_field"
                                  isDisabled={isElementInBoard}
                                  // CustomElement={CustomElement}
                                  primaryElement={primaryElement}
                                />
                              }
                              settings={item?.settings}
                              show={show}
                              isDragDisabled={isElementInBoard}
                            />
                          );
                        }
                      })}
                      {filterElements?.length == 0 && (
                        <div className="d-flex flex-column align-items-center gap-3 text-center mt-3">
                          <span
                            className="iconify display-1"
                            data-icon="fluent:box-search-20-regular"
                          ></span>
                          No Default Fields Found
                          <div
                            className="btn-primary btn pointer"
                            data-bs-toggle="collapse"
                            data-bs-target="#newF"
                          >
                            Create New Field
                          </div>
                        </div>
                      )}

                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </div>
            </div>
          </div>

          <div className="accordion-item">
            <h2 className="accordion-header">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapse2"
              >
                Default Fields
              </button>
            </h2>
            <div
              id="flush-collapse2"
              className="accordion-collapse collapse"
              data-bs-parent="#accordionFlush"
            >
              <div className="accordion-body p-0">
                <Droppable droppableId="DefaultElements">
                  {(provided, index) => (
                    <div
                      key={index}
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                      className="fm-component-cont mt-2 mb-4 row row-cols-1 gap-3 p-0 m-0"
                    >
                      {filterElements?.map((item, index) => {
                        //////console.log("item All Default>>", item);
                        const isElementInBoard = board?.some(
                          (element) => element.elementid === item.elementid
                        );
                        // setElementDisabled(isElementInBoard)/
                        const CustomElement = false;

                        // const str = item?.elementid?.split("-")[0];
                        //////console.log("item>><",isElementInBoard)
                        // const uuid = Math.floor(Math.random() * 10000);
                        if (isElementInBoard) {
                          return (
                            <div>
                              <Element
                                key={index}
                                id={item?.elementid}
                                type={item?.type}
                                name={item?.name}
                                placeholder={item?.settings}
                                field_type="default_field"
                                isDisabled={isElementInBoard}
                                CustomElement={CustomElement}
                              />
                            </div>
                          );
                        } else {
                          return (
                            <DragableElement
                              id={item?.elementid}
                              key={item.elementid} //if we pass key here then it will give error and drag and drop will not work
                              index={index}
                              element={
                                <Element
                                  id={item?.elementid}
                                  type={item?.type}
                                  name={item?.name}
                                  placeholder={item?.settings}
                                  field_type="default_field"
                                  isDisabled={isElementInBoard}
                                  CustomElement={CustomElement}
                                />
                              }
                              settings={item?.settings}
                              show={show}
                              isDragDisabled={isElementInBoard}
                            />
                          );
                        }
                      })}
                      {filterElements?.length == 0 && (
                        <div className="d-flex flex-column align-items-center gap-3 text-center mt-3">
                          <span
                            className="iconify display-1"
                            data-icon="fluent:box-search-20-regular"
                          ></span>
                          No Default Fields Found
                          <div
                            className="btn-primary btn pointer"
                            data-bs-toggle="collapse"
                            data-bs-target="#newF"
                          >
                            Create New Field
                          </div>
                        </div>
                      )}

                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </div>
            </div>
          </div>

          <div className="accordion-item">
            <h2 className="accordion-header">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapse3"
              >
                Custom Fields
              </button>
            </h2>
            <div
              id="flush-collapse3"
              className="accordion-collapse collapse"
              data-bs-parent="#accordionFlush"
            >
              <div className="accordion-body p-0">
                <Droppable droppableId="CustomElements" isDropDisabled={true}>
                  {(provided, index) => (
                    <div
                      key={index}
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                      className="fm-component-cont mt-2 mb-4 row row-cols-1 gap-3 p-0 m-0"
                    >
                      {filterCustomElements?.map((item, index) => {
                        //////console.log("item All Custom>>", item)
                        const CustomElement = true;
                        const isElementInBoard = board?.some(
                          (element) => element.elementid === item.elementid
                        );
                        // const str=item?.elementid?.split('-')[0]
                        //////console.log(
                        //   "ElementINBoard",
                        //   isElementInBoard,
                        //   board,
                        //   item.element
                        // );
                        if (isElementInBoard) {
                          // return null
                          return (
                            <Element
                              key={index}
                              id={item?.elementid}
                              type={item.type}
                              name={item?.name}
                              field_type="default_field"
                              placeholder={item?.settings}
                              isDisabled={isElementInBoard}
                              CustomElement={CustomElement}
                            />
                          );
                        } else {
                          return (
                            <DragableElement
                              id={item?.elementid}
                              key={item?.elementid} //if we pass key here then it will give error and drag and drop will not work
                              index={index}
                              element={
                                <Element
                                  id={item?.elementid}
                                  type={item.type}
                                  name={item?.name}
                                  placeholder={item?.settings}
                                  field_type="default_field"
                                  isDisabled={isElementInBoard}
                                  CustomElement={CustomElement}
                                />
                              }
                              settings={item?.settings}
                              show={show}
                              // isDragDisabled={isElementInBoard}
                            />
                          );
                        }
                      })}
                      {filterCustomElements?.length == 0 && (
                        <div className="d-flex flex-column align-items-center gap-3 text-center mt-3">
                          <span
                            className="iconify display-1"
                            data-icon="fluent:box-search-20-regular"
                          ></span>
                          No Custom Fields Found For The Match
                          <div
                            className="btn-primary btn pointer"
                            data-bs-toggle="collapse"
                            data-bs-target="#newF"
                          >
                            Create New Field
                          </div>
                        </div>
                      )}

                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default FormBuilderRightSideBar;
