import React from "react";

const PrimaryFields = ( { title, description, options, selectedFields, handleFieldChange } ) => {
    return (
        <div className="mb-4">
            <h6>{title}</h6>
            <p>{description}</p>
            {options.map( ( item, index ) => (
                <div key={index} className="form-check">
                    <input
                        className="form-check-input mt-2"
                        type="checkbox"
                        id={`field-${ index }`}
                        onChange={( e ) => handleFieldChange( e, item )}
                        checked={selectedFields.includes( item.elementid )}
                    />
                    <label className="form-check-label pt-2" htmlFor={`field-${ index }`}>
                        {item.name}{" "}
                        <small className="text-secondary ml-1">({item.description})</small>
                    </label>
                </div>
            ) )}
        </div>
    );
};

export default PrimaryFields;
