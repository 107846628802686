import React from "react";
import Button from "react-bootstrap/Button";
import { gql, useApolloClient } from "@apollo/client";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const GetEventData = gql`
  query GetWorkflowSessionDataBySessionId($sessionId: String!) {
  GetWorkflowSessionDataBySessionId(session_id: $sessionId) {
    statusCode
    message
    data
  }
}
`;

const SingleWorkflowHistory = ({ workflow }) => {
  const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);
  const [selectedEventData, setSelectedEventData] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const client = useApolloClient(); // Apollo Client instance

  const handleViewData = async (sessionId) => {
    setLoading(true);
    try {
      const { data } = await client.query({
        query: GetEventData,
        variables: { sessionId },
      });
      setSelectedEventData(data?.GetWorkflowSessionDataBySessionId?.data || {});
    } catch (error) {
      console.error("Error fetching event data:", error);
    } finally {
      setLoading(false);
      setIsDrawerOpen(true);
    }
  };

  const closeDrawer = () => {
    setIsDrawerOpen(false);
    setSelectedEventData(null);
  };

  const renderDynamicContent = (data, level = 0) => {
    if (!data || (typeof data === 'object' && Object.keys(data).length === 0)) {
      return (
        <div className="card-g bg-light p-2 border rounded-2 m-2">
          <Typography variant="body1">No data available</Typography>
        </div>
      );
    }
  
    // If it's the top level, only reverse the keys of the "data" object
    let entries = Object.entries(data);
  
    if (level === 0) {
      // Reverse only the top-level keys
      entries = entries.reverse();
    }
  
    const accordionHeaderStyle = {
      backgroundColor: "#f1f1f1",
      borderBottom: "1px solid #ddd",
      padding: "10px 20px",
      borderRadius: "4px",
    };
  
    return entries.map(([key, value]) => {
      const indentStyle = {
        marginLeft: `${level * 4}px`,
        display: "block",
        marginBottom: "5px",
      };
  
      // If it's at the top level (level 0), render an Accordion
      if (level === 0) {
        return (
          <Accordion key={key}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`${key}-content`}
              id={`${key}-header`}
              style={accordionHeaderStyle}
            >
              <Typography variant="body1">
                <b>{key}</b>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div className="card-g bg-white p-1 border rounded-2 mt-2">
                {Array.isArray(value) ? (
                  value.map((item, index) => (
                    <div key={index} style={{ marginBottom: "5px" }}>
                      {typeof item === 'object' ? (
                        <>
                          <strong>Index {index}:</strong>
                          {renderDynamicContent(item, level + 1)}
                        </>
                      ) : (
                        <span>{item}</span>
                      )}
                    </div>
                  ))
                ) : (
                  renderDynamicContent(value, level + 1)
                )}
              </div>
            </AccordionDetails>
          </Accordion>
        );
      }
  
      // If it's an object or array (but not at the top level), render it recursively
      if (typeof value === 'object' && value !== null) {
        return (
          <div key={key} style={indentStyle} className="card-g bg-white p-1 border rounded-2 m-2">
            <strong>{key + ": "}</strong>
            <div style={{ marginLeft: "5px" }}>
              {Array.isArray(value) ? (
                value.map((item, index) => (
                  <div key={index} style={{ marginBottom: "5px" }}>
                    {typeof item === 'object' ? (
                      <>
                        <strong>{key === "addresses" ? key.slice(0, -2) : key.slice(0, -1)} {index+1}:</strong>
                        {renderDynamicContent(item, level + 1)}
                      </>
                    ) : (
                      <span>{item}</span>
                    )}
                  </div>
                ))
              ) : (
                renderDynamicContent(value, level + 1)
              )}
            </div>
          </div>
        );
      }
  
      // If it's not an object or array, show the raw value
      return (
        <div key={key} style={indentStyle} className="card-g bg-white p-1 border rounded-2 m-2">
          <strong>{key + ": "}</strong>
          <span>
            {typeof value === 'string' ? value : JSON.stringify(value).replace(/"/g, '')}
          </span>
        </div>
      );
    });
  };
  
  
  return (
    <>
      <div
        className="d-md-flex flex-wrap border bg-white"
        key={workflow.session_id}
      >
        <div className="d-flex flex-wrap col-12 col-md-3 align-items-left justify-content-between pb-2 pt-4 py-md-3 px-4 border-0 border-end border">
          <p className="p-0 m-0">{workflow?.workflow_id}</p>
        </div>
        <div className="d-flex align-items-center justify-content-left px-4 col col-md-2 border-0 border-end border">
          <p className="p-0 m-0">{workflow?.workflow_name}</p>
        </div>
        <div className="d-flex align-items-center justify-content-left px-4 col col-md-2 border-0 border-end">
          {workflow?.workflow_status === "FAILED" && (
            <div className="d-flex align-items-center">
              <i className="bx bxs-x-circle fs-5 text-danger me-2"></i>
              <span className="text-danger">Failed</span>
            </div>
          )}
          {workflow?.workflow_status === "SUCCESS" && (
            <div className="d-flex align-items-center">
              <i className="bx bxs-check-circle fs-5 text-success me-2"></i>
              <span className="text-success">Success</span>
            </div>
          )}
          {workflow?.workflow_status === "IN_PROGRESS" && (
            <div className="d-flex align-items-center">
              <i className="bx bxs-hourglass fs-5 text-warning me-2"></i>
              <span className="text-warning">In Progress</span>
            </div>
          )}
          {workflow?.workflow_status === "FILTERED" && (
            <div className="d-flex align-items-center">
              <i className="bx bx-filter fs-5 text-secondary me-2"></i>
              <span className="text-secondary">Filtered</span>
            </div>
          )}
        </div>
        <div className="d-flex align-items-center justify-content-left px-4 col col-md-2 border-0 border-end border">
          {new Date(workflow?.created_at).toLocaleDateString()}{" "}
          {new Date(workflow?.created_at).toLocaleTimeString()}
        </div>
        <div className="d-flex align-items-center justify-content-left px-2 col col-md-2 border-0 border-end border">
          <i
            className="iconify p-0 fs-3 text-primary"
            data-icon="ic:sharp-bolt"
          ></i>
          {workflow?.trigger_type}
        </div>
        <div className="d-flex align-items-center justify-content-left p-2 col col-md-1 border-0 border-end border">
          <Button
            className="btn btn-primary"
            onClick={() => handleViewData(workflow?.session_id)}
            disabled={loading}
          >
            {loading ? "Loading..." : "View Data"}
          </Button>
        </div>
      </div>

      {/* Drawer for showing Event Data */}
      <Drawer
        anchor="right"
        open={isDrawerOpen}
        onClose={closeDrawer}
        PaperProps={{
          style: {
            width: "600px",
            padding: "20px",
          },
        }}
      >
        <div className="d-flex justify-content-between align-items-center">
          <h5>Event Data</h5>
          <IconButton onClick={closeDrawer}>
            <CloseIcon />
          </IconButton>
        </div>
        <hr />
        {selectedEventData ? (
          <div className="p-2 border bg-color-1 mb-3 rounded-3 event-data-box mt-2">
            {renderDynamicContent(selectedEventData)}
          </div>
        ) : (
          <p>No data available</p>
        )}
      </Drawer>
    </>
  );
};

export default SingleWorkflowHistory;
