// CheckboxField.js
import React from "react";

const CheckboxField = ( { id, name, checked, onChange, label } ) => {
    return (
        <div className="mb-4">
            <div className="form-check">
                <input
                    type="checkbox"
                    id={id}
                    name={name}
                    checked={checked}
                    onChange={onChange}
                    className="form-check-input"
                />
                <label htmlFor={id} className="form-check-label">
                    {label}
                </label>
            </div>
        </div>
    );
};

export default CheckboxField;
