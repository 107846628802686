import React, { useState } from "react";
import Switch from "react-switch"; // Assuming you're using this library
import { gql, useMutation } from "@apollo/client";
import { useQuery } from "@apollo/client";
import { useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import isEqual from "lodash.isequal";

const getFubData = gql`
  query GetFubAllData($type: String!) {
    GetFubAllData(type: $type) {
      output
      status
      info
    }
  }
`;
const UPDATE_FORM_SETTINGS = gql`
  mutation UpdateFormSettings(
    $teamId: String!
    $formId: String
    $backfillLinkForExistingLeads: Boolean
    $populateLinkForNewLeads: Boolean
    $leadCustomFieldName: String
    $leadFormRedirectUrl: String
    $redirectPageTimeout: Boolean
    $thankYouPageMessage: String
    $leadFormRedirectSetting: String
    $updateLeadTag: String
  ) {
    UpdateFormSettings(
      team_id: $teamId

      form_id: $formId
      backfill_link_for_existing_leads: $backfillLinkForExistingLeads
      populate_link_for_new_leads: $populateLinkForNewLeads
      lead_custom_field_name: $leadCustomFieldName
      lead_form_redirect_url: $leadFormRedirectUrl
      redirect_page_timeout: $redirectPageTimeout
      thank_you_page_message: $thankYouPageMessage
      lead_form_redirect_setting: $leadFormRedirectSetting
      update_lead_tag: $updateLeadTag
    ) {
      team_id
      form_id
    }
  }
`;

export const SettingPageForLeadForm = ({ GetFormData, setFormLoader,leadtype }) => {
  const FormParam = useParams();

  // Single state object for the entire form
  const [formData, setFormData] = useState({
    selectedCustomField: "",
    populateLinkToggle: false,
    backfillLinkToggle: false,
    redirect: "",
    thankYouMessage: "",
    redirectTime: false,
    urlLink: "http://", // Default value
    urlError: "",
  });
  const [updateFormSetting] = useMutation(UPDATE_FORM_SETTINGS);
  const isInitialRender = useRef(true);
  const previousFormDatas = useRef({});
  const badgeRef = useRef(null);
  const [formName, setFormName] = useState(null);

  const handleBlur = () => {
    const updatedName = badgeRef.current.innerText.trim();
    setFormName(updatedName);

  };

  useEffect(() => {
    if (GetFormData) {
      setFormData({
        selectedCustomField: GetFormData?.lead_custom_field_name || "",
        populateLinkToggle: GetFormData?.populate_link_for_new_leads || false,
        backfillLinkToggle:
          GetFormData?.backfill_link_for_existing_leads || false,
        redirect: GetFormData?.lead_form_redirect_setting || "",
        thankYouMessage: GetFormData?.thank_you_page_message || "",
        redirectTime: GetFormData?.redirect_page_timeout || false,
        urlLink: GetFormData?.lead_form_redirect_url || "http://",
        urlError: "",
      });
      setFormName(
        GetFormData?.update_lead_tag !== "" && GetFormData?.update_lead_tag != null
          ? GetFormData?.update_lead_tag
          : GetFormData?.form_name || ""
      );    }
  }, [GetFormData]);
  const [leadSettings] = useState([
    {
      id: 1,
      name: "Redirect to another page",
      value: "another_page",
    },
    {
      id: 2,
      name: "Display an Inline thank you page (default)",
      value: "inline_page",
    },
  ]);

  // Handler for generic input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const { data: FubCustomField } = useQuery(getFubData, {
    variables: {
      type: "customfields",
    },
  });
  // Handler for Switch toggles
  const handleSwitchToggle = (name, checked) => {
    setFormData((prev) => ({
      ...prev,
      [name]: checked,
    }));
  };

  // Handler for radio button changes
  const handleRadioChange = (e) => {
    setFormData((prev) => ({
      ...prev,
      redirect: e.target.value,
    }));
  };

  const handleUrlChange = (e) => {
    let url = e.target.value;

    // Update the form data
    setFormData((prevData) => ({
      ...prevData,
      urlLink: url,
    }));

    // URL Validation using regex
    const urlPattern =
      /^(https?:\/\/)?([a-z0-9-]+\.)+[a-z]{2,6}(:[0-9]{1,5})?(\/[a-z0-9-_.]*)*\/?$/;

    // Set error if the URL does not match the pattern
    if (url && !urlPattern.test(url)) {
      setFormData((prevData) => ({
        ...prevData,
        urlError: "Please enter a valid URL (e.g., http://www.example.com)",
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        urlError: "",
      }));
    }
  };

  useEffect(() => {
    // Skip the first render
    if (isInitialRender.current) {
      isInitialRender.current = false;
      previousFormDatas.current = { ...formData }; // Initialize previous data
      return;
    }

    // Compare formData with previousFormData
    const hasFormDataChanged = !isEqual(formData, previousFormDatas.current);
    const hasFormNameChanged = formName !== previousFormDatas.current.formName;

    if (hasFormDataChanged || hasFormNameChanged) {
      // Trigger the mutation
      setFormLoader(true);

      updateFormSetting({
        variables: {
          teamId: String(GetFormData?.team_id),
          formId: FormParam?.id,
          backfillLinkForExistingLeads: formData?.backfillLinkToggle,
          populateLinkForNewLeads: formData?.populateLinkToggle,
          leadCustomFieldName: formData?.selectedCustomField,
          leadFormRedirectUrl: formData?.urlLink,
          redirectPageTimeout: formData?.redirectTime,
          thankYouPageMessage: formData?.thankYouMessage,
          leadFormRedirectSetting: formData?.redirect,
          updateLeadTag: formName, // Send updated formName
        },
      })
        .then(() => {
          previousFormDatas.current = { ...formData, formName }; // Update with formName
        })
        .catch((error) => {
          console.error("Mutation error:", error);
        })
        .finally(() => {
          setFormLoader(false);
        });
    }
  }, [formData, formName]); // Depend on both formData and formName

  const [elementId, setElementId] = useState("");
  const [value, setValue] = useState("");
  const [params, setParams] = useState([]);
  const [editingIndex, setEditingIndex] = useState(null);
  // const baseUrl = `http://localhost:3000/forms/${FormParam.id}`; // Adjust to your base URL
   const baseUrl = `https://app.datalabz.re/forms/${FormParam?.id}`;
  const handleAddParameter = () => {
    if (elementId) {
      if (editingIndex !== null) {
        // If editing, update the existing parameter
        const updatedParams = [...params];
        updatedParams[editingIndex] = `${elementId}=${value}`;
        setParams(updatedParams);
        setEditingIndex(null); // Reset editing index after update
      } else {
        // If adding new parameter
        setParams((prevParams) => [...prevParams, `${elementId}=${value}`]);
      }

      // Reset input fields
      setElementId("");
      setValue("");
    }
  };

  const handleEditParameter = (index) => {
    const param = params[index]?.split("=");
    setElementId(param[0]);
    setValue(param[1]);
    setEditingIndex(index); // Set the index to be edited
  };

  const handleDeleteParameter = (index) => {
    const updatedParams = params?.filter((_, i) => i !== index);
    setParams(updatedParams);
  };

  const generateUrl = () => {
    return `${baseUrl}?${params?.join("&")}`;
  };
  const handleCopyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
  };
 


  return (
    <React.Fragment>
      {String(leadtype) === "update_lead_form" && (
        <div className="mx-auto bg-white d-flex flex-column flex-wrap rounded-3 border position-relative mb-5 p-4">
          <div className="d-flex justify-content-between align-items-center mb-3">
            <div>
              <label className="fw-bold" htmlFor="customFieldToggle">
                Custom field form URL settings{" "}
                <span
                  className="rounded-pill fw-light text-dark badge bg-warning"
                  style={{ marginLeft: "5px" }}
                >
                  Beta
                </span>
              </label>
              <p className="text-muted mb-0">Setup your custom field</p>
            </div>
          </div>

          <div className="mb-4 mt-2">
            <label htmlFor="customField" className="form-label">
              Select Custom Field <span style={{ color: "red" }}>*</span>
            </label>
            <select
              id="customField"
              className="form-select"
              aria-label="Select Custom Field"
              required
              value={formData?.selectedCustomField}
              onChange={(e) => {
                handleInputChange(e);
              }}
              name="selectedCustomField"
            >
              <option value="">Select Custom field</option>
              {FubCustomField?.GetFubAllData?.output?.map((item) => (
                <option key={item?.id} value={item?.name}>
                  {item?.label}
                </option>
              ))}
            </select>
          </div>

          <hr />

          <div className="d-flex justify-content-between align-items-center mb-4 mt-4">
            <div>
              <label className="fw-bold" htmlFor="populateLinkToggle">
                Populate link in FUB custom field when new leads are created
              </label>
              <p className="text-muted mb-0">
                Automatically populate link when a lead record is created
              </p>
            </div>
            <Switch
              onChange={(checked) =>
                handleSwitchToggle("populateLinkToggle", checked)
              }
              checked={formData.populateLinkToggle}
              onColor="#86d3ff"
              offColor="#ccc"
              handleDiameter={20}
              uncheckedIcon={false}
              checkedIcon={false}
              height={24}
              width={48}
            />
          </div>

          <hr />

          <div className="d-flex justify-content-between align-items-center mb-3 mt-3">
      <div>
        <label className="fw-bold" htmlFor="backfillLinkToggle">
          Backfill link in FUB custom fields for existing leads
        </label>
        <p className="text-muted mb-0">
          Apply
          <span
            className="badge bg-primary"
            contentEditable
            suppressContentEditableWarning
            onBlur={handleBlur}
            ref={badgeRef}
            style={{
              cursor: "pointer",
              minWidth: "10px", // Keeps the badge visible even when empty
              display: "inline-block",
              margin: "0 5px",
            }}
            title="Click to edit"
          >
            {formName}
          </span>
          to any lead record you want to add the link to
        </p>
      </div>
      <Switch
        onChange={(checked) => handleSwitchToggle("backfillLinkToggle", checked)}
        checked={formData.backfillLinkToggle}
        onColor="#86d3ff"
        offColor="#ccc"
        handleDiameter={20}
        uncheckedIcon={false}
        checkedIcon={false}
        height={24}
        width={48}
      />
    </div>
        </div>
      )}

      <div className="mx-auto bg-white d-flex flex-column flex-wrap rounded-3 border position-relative mb-5 p-4">
        <div className="w-100 d-flex flex-column m-0 mb-4 pb-2 border-bottom">
          <h5 className="fs-6 m-0 mb-2">Redirect Settings </h5>
          <p className="small text-secondary">
            Where would you be redirected to after filling lead forms.
          </p>
        </div>

        <div className="mb-4">
          {leadSettings?.map((item, index) => (
            <div className="form-check mt-3F" key={index}>
              <input
                className="form-check-input"
                type="radio"
                name="redirect"
                id={`flexRadioDefault${index}`}
                value={item?.value}
                checked={String(formData?.redirect) === String(item?.value)}
                onChange={handleRadioChange}
              />
              <label
                className="form-check-label"
                htmlFor={`flexRadioDefault${index}`}
              >
                {item?.name}
              </label>
            </div>
          ))}
        </div>

        {String(formData?.redirect) === "inline_page" && (
          <>
            <div className="mb-4 dispo-text">
              <label className="mb-2">
                <span>Thank you message</span>
              </label>
              <textarea
                className="form-control input-bn"
                name="thankYouMessage"
                value={formData?.thankYouMessage || ""}
                onChange={handleInputChange}
              ></textarea>
            </div>
            <div className="">
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="redirectTime"
                  checked={formData?.redirectTime}
                  onChange={(e) =>
                    setFormData((prev) => ({
                      ...prev,
                      redirectTime: e.target.checked,
                    }))
                  }
                />
                <label
                  className="form-check-label small"
                  htmlFor="flexCheckDefault"
                >
                  Redirect back to the same form after 20 seconds
                </label>
              </div>
            </div>
          </>
        )}

        {String(formData?.redirect) === "another_page" && (
          <div className="mb-4">
            <label className="mb-2">
              <span>Redirect URL</span>
            </label>
            <input
              type="url"
              className="form-control input-bn"
              name="urlLink"
              value={formData?.urlLink}
              onChange={handleUrlChange}
              placeholder="http://www.example.com"
              pattern="https?://.*"
              required
            />
            <span style={{ color: "red" }}>{formData.urlError}</span>
          </div>
        )}
      </div>

      <div className="mx-auto bg-white d-flex flex-column flex-wrap rounded-3 border position-relative mb-5 p-4">
        <div className="w-100 d-flex flex-column m-0 mb-4 pb-2 border-bottom">
          <h5 className="fs-6 m-0 mb-2">
            Generate Dynamic URL with Pre-populated Fields
          </h5>
          <p className="small text-secondary">
            Add dynamic parameters to the URL based on the selected form fields.
          </p>
        </div>

        <div className="d-flex align-items-center mb-4 bg-color-1 border p-3 justify-content-between rounded-2 position-relative">
          {/* Select Dropdown for Element ID */}
          <div className="col-md-5 px-2">
            <label htmlFor="elementid-dropdown">Select Form Fields</label>
            <select
              className="form-control form-select"
              id="elementid-dropdown"
              value={elementId}
              onChange={(e) => setElementId(e.target.value)}
            >
              <option value="" disabled hidden>
                Select Field
              </option>
              {GetFormData?.form_fields
                ?.filter(
                  (field) =>
                    field?.type !== "FreeText" && field?.type !== "Headings" // Exclude FreeText and heading types
                )
                ?.map((field) => (
                  <option key={field?.elementid} value={field?.elementid}>
                    {field?.settings?.label || field?.name}
                  </option>
                ))}
            </select>
          </div>

          {/* Equal sign in the middle */}
          <div className="mx-2 mt-4">=</div>

          {/* Input Field for Value */}
          <div className="col-md-5 px-2">
            <label htmlFor="value-input">Enter Value</label>
            <input
              type="text"
              className="form-control"
              id="value-input"
              placeholder="Enter value"
              value={value}
              onChange={(e) => setValue(e.target.value)}
            />
          </div>
        </div>

        {/* Add/Update button */}
        <button
          className="btn btn-primary btn-block mt-3"
          onClick={handleAddParameter}
        >
          {editingIndex !== null ? "Update Parameter" : "Add Parameter"}
        </button>

        {/* Display generated URL */}
        <hr />

        {/* Parameter List */}
        {params?.length > 0 && (
          <>
            <h5 className="mt-4">Parameters List</h5>
            <ul className="list-group">
              {params?.map((param, index) => (
                <li
                  key={index}
                  className="list-group-item d-flex justify-content-between align-items-center"
                >
                  <span>{param}</span>
                  <div>
                    <button
                      className="btn btn-warning btn-sm mx-1"
                      onClick={() => handleEditParameter(index)}
                    >
                      Edit
                    </button>
                    <button
                      className="btn btn-danger btn-sm"
                      onClick={() => handleDeleteParameter(index)}
                    >
                      Delete
                    </button>
                  </div>
                </li>
              ))}
            </ul>
          </>
        )}

        {params?.length > 0 && (
          <>
            {" "}
            <h5 className="mt-4">Generated URL</h5>
            <div className="modal-body pb-4 ">
              {/* <p className="fs-6">Embed a form on your website</p> */}
              <div className="d-flex align-items-center gap-3 border rounded-3 p-3">
                <div className="d-flex flex-wrap">{generateUrl()}</div>

              </div>
              <button
                type="button"
                className="btn btn-primary mt-3"
                data-bs-dismiss="modal"
                onClick={() => {
                  handleCopyToClipboard(generateUrl());
                }}
              >
                Copy to clipboard
                <i className="iconify" data-icon="mage:copy"></i>
              </button>
            </div>
          </>
        )}
      </div>
    </React.Fragment>
  );
};
